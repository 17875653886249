export const getDKRolesForUser = async (userid) => {
  const resp = await logFetchAJAX(getUrl(`/api/iam/users/${userid}/roles`), {
    method: "GET",
  })
  const rassocs = resp["rassocs"] || [];
  const out = {}
  rassocs.forEach(rassoc => {
    const restype = rassoc.resource_type || ""
    if (restype != "proxy") {
      let proxyName = rassoc.resource_id;
      if ((proxyName == "") || (proxyName == 'undefined') || (typeof(proxyName) == 'undefined')) {
        proxyName = "Default";
      }
      if (!(proxyName in out)) {
        out[proxyName] = []
      }
      out[proxyName].push(rassoc.role_name)
    }
  })
  console.log('getDKRolesForUser returning:', out)
  return out 
}
